import { defineStore } from 'pinia'
import { debounce, update } from 'lodash';
import secureLocalStorage from '@/libs/secure-ls';

const currentSetting = localStorage.getItem('setting')

export const useSettingStore = defineStore({
  id: 'setting',
  state: () => ({
    setting: currentSetting ? JSON.parse(currentSetting) : []
  }),
  getters: {
    groups(state) {
      return state.setting.map(({ group }) => group).filter((value, index, self) => self.indexOf(value) === index)
    },
    mapGroup(state) {
      const r = {};
      (this.setting || []).forEach(v => {
        if (!r[v.group]) r[v.group] = [];
        r[v.group].push(v);
      });
      return r;
    },
  },
  actions: {
    fetchSetting() {
      axios
        .get('/setting', { headers: { 'X-CACHE-INVALIDATE': 1 } })
        .then((response) => {
          this.setting = response.data.data.map(v => {
            switch (v.type) {
              case 'boolean':
                return { ...v, value: !!parseInt(v.value) }
              case 'number':
                return { ...v, value: parseInt(v.value) }
              default:
                return { ...v, value: v.value }
            }
          })
          localStorage.setItem('setting', JSON.stringify(this.setting))
        })
        .catch((error) => {
          console.error(error)
        })
    },
    update(key, value) {
      const index = this.setting.findIndex((item) => item.key === key)
      if (index < 0) return;
      localStorage.setItem('setting', JSON.stringify(this.setting))
    },
    val(key) {
      const index = this.setting.findIndex((item) => item.key === key)
      if (index < 0) return;
      return this.setting[index].value
    }
  },
})