<template>
  <!-- modal Pasien Create-->
  <b-modal
    :id="'modal-' + type"
    ref="modalCreate"
    :title="modalTitle"
    :static="true"
    modal-class="modal-fullscreen"
    scrollable
    ok-title="Simpan"
    cancel-title="Tutup"
    no-close-on-backdrop
    @ok="handleOk"
    @close="closeModal"
  >
    <!-- <b-card > -->
    <validation-observer ref="addPasien">
      <b-form @submit.prevent="formSubmitted">
        <b-row class="px-1">
          <b-col md="12">
            <div class="divider divider-primary mt-0">
              <div class="divider-text">
                Data Identitas
              </div>
            </div>
          </b-col>
          <b-col md="3" v-if="editableNorm && this.type === 'create'">
            <ValidationProvider
              v-slot="validationContext"
              name="NORM"
              rules="required|numeric|min:1"
            >
              <b-form-group>
                <div class="form-label-group">
                  <b-input-group>
                    <b-form-input
                      id="gelar-depan"
                      v-model="form['norm']"
                      size="sm"
                      placeholder="NORM"
                      :state="getValidationState(validationContext)"
                    />
                    <b-input-group-append>
                      <b-button size="sm" @click="getLatestNorm">
                        <icon icon="material-symbols:download" />
                      </b-button>
                      <b-button size="sm" @click="checkNorm">
                        <icon icon="material-symbols:search" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <label>NORM</label>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <ValidationProvider
              v-slot="validationContext"
              name="NIK"
              rules="required|max:16|min:16|numeric"
            >
              <b-form-group>
                <div class="form-label-group">
                  <b-input-group>
                    <b-form-input
                      id="gelar-depan"
                      v-model="form['nik']"
                      size="sm"
                      placeholder="NIK"
                      :state="getValidationState(validationContext)"
                      @input="
                        (no) =>
                          nikHandler(
                            no,
                            'tanggal_lahir_formatted',
                            'jenis_kelamin'
                          )
                      "
                    />
                    <b-input-group-append>
                      <b-button size="sm" @click="autofillBy('nik', form['nik'])">
                        <icon icon="material-symbols:search" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <label>NIK</label>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <ValidationProvider
              v-slot="validationContext"
              name="No. BPJS"
              rules="max:13|min:13|numeric"
            >
              <b-form-group>
                <div class="form-label-group">
                  <b-input-group>
                    <b-form-input
                      id="gelar-depan"
                      v-model="form['no_bpjs']"
                      size="sm"
                      placeholder="No. BPJS"
                      :state="getValidationState(validationContext)"
                    />
                    <b-input-group-append>
                      <b-button size="sm" @click="autofillBy('noka', form['no_bpjs'])">
                        <icon icon="material-symbols:search" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <label>No. BPJS</label>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="12">
            <div class="divider divider-primary mt-0">
              <div class="divider-text">
                Data Pribadi
              </div>
            </div>
          </b-col>
          <!-- data pribadi tab -->
          <b-row class="px-1">
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="gelar-depan"
                    v-model="form['gelar_depan']"
                    size="sm"
                    placeholder="Gelar Depan eg: dr/Ny/Hj/Tn"
                  />
                  <label>Gelar Depan</label>
                </div>
              </b-form-group>
            </b-col>
            <!-- Group 1 -->
            <b-col md="6">
              <ValidationProvider
                v-slot="validationContext"
                name="Nama"
                rules="required"
              >
                <b-form-group>
                  <div class="form-label-group">
                    <b-form-input
                      id="nama-lengkap"
                      v-model="form['nama']"
                      size="sm"
                      :state="getValidationState(validationContext)"
                      placeholder="[ Nama Lengkap Tanpa Gelar ]"
                    />
                    <label>Nama Lengkap Tanpa Gelar</label>
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="nama-panggilan"
                    v-model="form['panggilan']"
                    size="sm"
                    placeholder="[ Nama Panggilan ]"
                  />
                  <label>Nama Panggilan</label>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="gelar-belakang"
                    v-model="form['gelar_belakang']"
                    size="sm"
                    placeholder="[ Gelar Belakang ]"
                  />
                  <label>Gelar Belakang</label>
                </div>
              </b-form-group>
            </b-col>
            <!-- End Group 1 -->
            <!-- Start Group 2 -->
            <b-col md="2">
              <ValidationProvider
                v-slot="validationContext"
                name="Tempat Lahir"
                rules="required|alpha"
              >
                <b-form-group>
                  <div class="form-label-group">
                    <b-form-input
                      id="tempat-lahir"
                      v-model="form['tempat_lahir']"
                      size="sm"
                      :state="getValidationState(validationContext)"
                      placeholder="[ Tempat Lahir ]"
                    />
                    <label>Tempat Lahir</label>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="2">
              <ValidationProvider
                v-slot="validationContext"
                name="Tanggal Lahir"
                rules="required"
              >
                <b-form-group>
                  <div class="form-label-group">
                    <b-form-input
                      id="tanggal-lahir"
                      v-model="form['tanggal_lahir_formatted']"
                      v-mask="'##/##/####'"
                      size="sm"
                      :max="nowDate"
                      locale="id"
                      type="tel"
                      placeholder="[ Tgl. Lahir (DD/MMM/YYYY) ]"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['jenis_kelamin']"
                    class="select-size-sm"
                    :options="references['Jenis Kelamin']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Jenis Kelamin ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <!-- End Group 2 -->
            <!-- Start Group 3 -->
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['agama']"
                    class="select-size-sm"
                    :options="references['Agama']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Agama ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['status_perkawinan']"
                    class="select-size-sm"
                    :options="references['Status Perkawinan']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Status Perkawinan ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['pendidikan']"
                    class="select-size-sm"
                    :options="references['Pendidikan']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Pendidikan ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['pekerjaan']"
                    class="select-size-sm"
                    :options="references['Pekerjaan']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Pekerjaan ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['suku']"
                    class="select-size-sm"
                    :options="references['Daftar Suku']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Suku ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['golongan_darah']"
                    class="select-size-sm"
                    :options="references['Golongan Darah']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Golongan Darah ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <!-- End Group 3 -->
            <!-- Start Group 4 -->
            <b-col md="3">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['kewarganegaraan']"
                    class="select-size-sm"
                    :options="references['kewarganegaraan']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Kewarganegaraan ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3"></b-col>
            <b-col md="3">
              <ValidationProvider
                v-slot="validationContext"
                name="No. Handphone"
                rules="required"
              >
                <b-form-group>
                  <div class="form-label-group">
                    <b-form-input
                      id="no-telepon"
                      v-model="form['nomor_kontak_telp']"
                      size="sm"
                      :state="getValidationState(validationContext)"
                      type="number"
                      placeholder="[ No. Handphone ]"
                      @input="syncPhone"
                    />
                    <label>No. Handphone</label>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="3">
              <ValidationProvider
                v-slot="validationContext"
                name="No. WhatsApp"
                rules="required"
              >
                <b-form-checkbox
                  v-model="sameAsPhone"
                  :value="true"
                  class="float-right ml-1"
                >
                  <small>Sama dgn No. Telepon</small>
                </b-form-checkbox>
                <b-form-group>
                  <div class="form-label-group">
                    <b-form-input
                      id="no-whatsapp"
                      v-model="form['nomor_kontak_wa']"
                      size="sm"
                      :state="getValidationState(validationContext)"
                      type="number"
                      placeholder="[ No. Whatsapp ]"
                    />
                    <label>No. Whatsapp</label>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="3">
              <ValidationProvider
                v-slot="validationContext"
                name="Email"
                rules="email"
              >
                <b-form-group>
                  <div class="form-label-group">
                    <b-form-input
                      id="mc-email"
                      v-model="form['email']"
                      size="sm"
                      :state="getValidationState(validationContext)"
                      name="email"
                      type="email"
                      placeholder="[ Email ]"
                    />
                    <label>Email</label>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!-- End Group 4 -->
          </b-row>
          <!-- perusahaaan -->
          <b-col md="12">
            <div class="divider divider-primary mt-0">
              <div class="divider-text">
                Perusahaan
              </div>
            </div>
          </b-col>

          <b-row class="px-1">
            <b-col md="6">
              <b-form-group label="Perusahaan" label-for="perusahaan">
                <b-form-input v-model="form['perusahaan']" list="perusahaan" placeholder="[ Perusahaan ]" size="sm" />
                <datalist id="perusahaan" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Bagian" label-for="bagian">
                <b-form-input v-model="form['bagian']" list="bagian" placeholder="[ Bagian ]" size="sm" />
                <datalist id="bagian" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-col md="12">
            <div class="divider divider-primary mt-0">
              <div class="divider-text">
                Alamat Lengkap
              </div>
            </div>
          </b-col>
          <!-- alamat lengkap tab -->
          <b-row class="px-1">
            <b-col md="6">
              <!-- Start Group 1 -->
              <b-row class="mt-3">
                <b-col md="12">
                  <b-form-group>
                    <div class="form-label-group">
                      <b-form-input
                        id="alamat"
                        v-model="form['alamat_identitas']"
                        placeholder="[ Alamat Sesuai KTP ]"
                        size="sm"
                        @input="syncAlamat"
                      />
                      <label for="">Alamat Sesuai KTP</label>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- End Group 1 -->
              <!-- Start Group 2 -->
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <div class="form-label-group">
                      <v-select
                        v-model="form['provinsi_identitas']"
                        class="select-size-sm"
                        :options="provinsi_identitas"
                        label="title"
                        placeholder="[ Provinsi ]"
                        @change="syncAlamat"
                        @input="
                          $store.dispatch('wilayahIdentitas/getWilayah')
                          syncAlamat()
                        "
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <div class="form-label-group">
                      <v-select
                        v-model="form['wilayah_identitas']"
                        class="select-size-sm"
                        :options="wilayah_identitas"
                        label="title"
                        placeholder="[ Kabupaten/Kota ]"
                        @change="syncAlamat"
                        @input="
                          $store.dispatch('wilayahIdentitas/getKecamatan')
                          syncAlamat()
                        "
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <div class="form-label-group">
                      <v-select
                        v-model="form['kecamatan_identitas']"
                        class="select-size-sm"
                        :options="kecamatan_identitas"
                        label="title"
                        placeholder="[ Kecamatan ]"
                        @input="
                          $store.dispatch('wilayahIdentitas/getKelurahan')
                          syncAlamat()
                        "
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <div class="form-label-group">
                      <v-select
                        v-model="form['kelurahan_identitas']"
                        class="select-size-sm"
                        :options="kelurahan_identitas"
                        label="title"
                        placeholder="[ Kelurahan ]"
                        @input="syncAlamat"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- End Group 2 -->
              <!-- Start Group 3 -->
              <b-row>
                <b-col md="3">
                  <ValidationProvider
                    v-slot="validationContext"
                    name="RT"
                    rules="numeric"
                  >
                    <b-form-group>
                      <b-input-group size="sm" prepend="RT">
                        <b-form-input
                          id="rt"
                          v-model="form['rt_identitas']"
                          size="sm"
                          :state="getValidationState(validationContext)"
                          @input="syncAlamat"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="3">
                  <ValidationProvider
                    v-slot="validationContext"
                    name="RW"
                    rules="numeric"
                  >
                    <b-form-group>
                      <b-input-group size="sm" prepend="RW">
                        <b-form-input
                          id="rw"
                          v-model="form['rw_identitas']"
                          size="sm"
                          :state="getValidationState(validationContext)"
                          @input="syncAlamat"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <ValidationProvider
                    v-slot="validationContext"
                    name="Kode Pos"
                    rules="numeric|digits:5"
                  >
                    <b-form-group>
                      <b-input-group size="sm" prepend="Kodepos">
                        <b-form-input
                          id="kodepos"
                          v-model="form['kodepos_identitas']"
                          size="sm"
                          :state="getValidationState(validationContext)"
                          @input="syncAlamat"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <!-- End Group 3 -->
            </b-col>
            <b-col md="6">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-checkbox
                    v-model="sameAsIdentitas"
                  >
                    Alamat Domisili Sama Dengan Alamat KTP
                  </b-form-checkbox>
                </div>
              </b-form-group>
              <!-- Start Group 1 -->
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <div class="form-label-group">
                      <b-form-input
                        id="alamat"
                        v-model="form['alamat']"
                        size="sm"
                        :disabled="sameAsIdentitas"
                        placeholder="[ Alamat Domisili ]"
                      />
                      <label>Alamat Domisili</label>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- End Group 1 -->
              <!-- Start Group 2 -->
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <div class="form-label-group">
                      <v-select
                        v-model="form['provinsi']"
                        class="select-size-sm"
                        :disabled="sameAsIdentitas"
                        :options="provinsi"
                        label="title"
                        placeholder="[ Provinsi ]"
                        @input="$store.dispatch('wilayah/getWilayah')"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <div class="form-label-group">
                      <v-select
                        v-model="form['wilayah']"
                        class="select-size-sm"
                        :disabled="sameAsIdentitas"
                        :options="wilayah"
                        placeholder="[ Kabupaten/Kota ]"
                        label="title"
                        @input="$store.dispatch('wilayah/getKecamatan')"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <div class="form-label-group">
                      <v-select
                        v-model="form['kecamatan']"
                        class="select-size-sm"
                        :disabled="sameAsIdentitas"
                        :options="kecamatan"
                        placeholder="[ Kecamatan ]"
                        label="title"
                        @input="$store.dispatch('wilayah/getKelurahan')"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <div class="form-label-group">
                      <v-select
                        v-model="form['kelurahan']"
                        class="select-size-sm"
                        :disabled="sameAsIdentitas"
                        :options="kelurahan"
                        placeholder="[ Kelurahan ]"
                        label="title"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- End Group 2 -->
              <!-- Start Group 3 -->
              <b-row>
                <b-col md="3">
                  <b-form-group>
                    <div class="form-label-group">
                      <b-input-group size="sm" prepend="RT">
                        <b-form-input
                          id="rt"
                          v-model="form['rt']"
                          size="sm"
                          :disabled="sameAsIdentitas"
                        />
                      </b-input-group>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <div class="form-label-group">
                      <b-input-group size="sm" prepend="RW">
                        <b-form-input
                          id="rw"
                          v-model="form['rw']"
                          size="sm"
                          :disabled="sameAsIdentitas"
                        />
                      </b-input-group>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <div class="form-label-group">
                      <b-input-group size="sm" prepend="Kodepos">
                        <b-form-input
                          id="kodepos"
                          v-model="form['kodepos']"
                          size="sm"
                          :disabled="sameAsIdentitas"
                        />
                      </b-input-group>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- End Group 3 -->
            </b-col>
          </b-row>
          <b-col md="12">
            <div class="divider divider-primary mt-0">
              <div class="divider-text">
                Data Keluarga
              </div>
            </div>
          </b-col>
          <b-row class="px-1 mt-1">
            <b-col md="12">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="mc-nama-ibu"
                    v-model="form['ibu_kandung']"
                    size="sm"
                    placeholder="[ Nama Lengkap Ibu Kandung Tanpa Gelar]"
                  />
                  <label for="">Nama Lengkap Ibu Kandung Tanpa Gelar</label>
                </div>
              </b-form-group>
              <h5 class="my-1">
                KELUARGA DEKAT LAINNYA:
              </h5>
            </b-col>
            <!-- Start Group 1 -->
            <b-col md="3">
              <b-form-group>
                <v-select
                  v-model="form['shdk_keluarga']"
                  class="select-size-sm"
                  :options="references['Status Hubungan Dalam Keluarga']"
                  :reduce="(option) => option.value"
                  label="title"
                  placeholder="[ Hubungan Keluarga ]"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="mc-nama-keluarga"
                    v-model="form['nama_keluarga']"
                    size="sm"
                    placeholder="[ Nama Anggota Keluarga ]"
                  />
                  <label>Nama Anggota Keluarga</label>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <v-select
                  v-model="form['jenis_kelamin_keluarga']"
                  class="select-size-sm"
                  :options="references['Jenis Kelamin']"
                  :reduce="(option) => option.value"
                  label="title"
                  placeholder="[ Jenis Kelamin ]"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="mc-alamat-keluarga"
                    v-model="form['alamat_keluarga']"
                    size="sm"
                    placeholder="[ Alamat Keluarga ]"
                  />
                  <label>Alamat Keluarga</label>
                </div>
              </b-form-group>
            </b-col>
            <!-- End Group 1 -->
            <!-- Start Group 2 -->
            <b-col md="3">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['id_keluarga']"
                    class="select-size-sm"
                    :options="references['Jenis Kartu Identitas']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="Jenis Kartu Identitas Keluarga"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="no-identitas"
                    size="sm"
                    placeholder="[ No. Identitas Keluarga ]"
                    @input="
                      (no) =>
                        nikHandler(
                          no,
                          'tanggal_lahir_keluarga_formatted',
                          'jenis_kelamin_keluarga'
                        )
                    "
                  />
                  <label>No. Identitas Keluarga</label>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="tempat-lahir"
                    v-model="form['tempat_lahir_keluarga']"
                    size="sm"
                    placeholder="[ Tempat Lahir Keluarga ]"
                  />
                  <label>Tempat Lahir Keluarga</label>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="tanggal-lahirkeluarga"
                    v-model="form['tanggal_lahir_keluarga_formatted']"
                    v-mask="'##/##/####'"
                    size="sm"
                    locale="id"
                    type="tel"
                    placeholder="Tanggal Lahir Keluarga"
                  />
                  <label>Tgl. Lahir Keluarga (DD/MM/YYYY)</label>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">

                  <v-select
                    v-model="form['kewarganegaraan_keluarga']"
                    class="select-size-sm"
                    :options="references['kewarganegaraan']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Kewarganegaraan ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <!-- End Group 2 -->
            <!-- Start Group 3 -->
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">

                  <v-select
                    v-model="form['agama_keluarga']"
                    class="select-size-sm"
                    :options="references['Agama']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Agama Keluarga ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['status_perkawinan_keluarga']"
                    class="select-size-sm"
                    :options="references['Status Perkawinan']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Status Perkawinan Keluarga ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['pendidikan_keluarga']"
                    class="select-size-sm"
                    :options="references['Pendidikan']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Pendidikan Keluarga ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['pekerjaan_keluarga']"
                    class="select-size-sm"
                    :options="references['Pekerjaan']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Pekerjaan Keluarga ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    v-model="form['suku_keluarga']"
                    class="select-size-sm"
                    :options="references['Daftar Suku']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Suku Keluarga ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <div class="form-label-group">
                  <v-select
                    id="GOLONGAN DARAH"
                    v-model="form['golongan_darah_keluarga']"
                    class="select-size-sm"
                    :options="references['Golongan Darah']"
                    :reduce="(option) => option.value"
                    label="title"
                    placeholder="[ Gol. Darah Keluarga ]"
                  />
                </div>
              </b-form-group>
            </b-col>
            <!-- End Group 3 -->
            <!-- Start Group 4 -->
            <b-col md="3">
              <b-form-group>
                <div class="form-label-group">

                  <b-form-input
                    id="no-telepon"
                    v-model="form['nomor_kontak_keluarga_telp']"
                    size="sm"
                    placeholder="[ No. Telepon Keluarga ]"
                    @input="syncPhoneKeluarga"
                  />
                  <label>No. Telepon Keluarga</label>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-checkbox
                v-model="sameAsPhoneKeluarga"
                :value="true"
                class="float-right ml-1"
              >
                <small> Sama dgn No. Telepon</small>
              </b-form-checkbox>
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="no-whatsapp"
                    v-model="form['nomor_kontak_keluarga_wa']"
                    size="sm"
                    placeholder="[ No. Whatsapp Keluarga ]"
                  />
                  <label>No. Whatsapp Keluarga</label>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <div class="form-label-group">
                  <b-form-input
                    id="mc-email"
                    v-model="form['email_keluarga']"
                    size="sm"
                    placeholder="[ Email Keluarga ]"
                  />
                  <label>Email Keluarga</label>
                </div>
              </b-form-group>
            </b-col>
            <!-- End Group 4 -->
          </b-row>
          <b-col md="12">
            <div class="divider divider-primary mt-0">
              <div class="divider-text">
                Dokumen Pendukung
              </div>
            </div>
          </b-col>
          <!-- kelengkapan dokumen tab -->
          <b-row class="mt-1 px-1">
            <!-- Start Group 1 -->
            <b-col md="4">
              <b-form-group
                label="KTP"
                label-for="ktp"
              >
                <preview-dokumen :url="form['file_ktp_url']" />
                <b-input-group>
                  <b-form-file
                    v-model="form['file_ktp']"
                    accept="image/*"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here..."
                    @input="(file) => previewImage(file, 'file_ktp_url')"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="BPJS"
                label-for="bpjs"
              >
                <preview-dokumen :url="form['file_bpjs_url']" />
                <b-input-group>
                  <b-form-file
                    v-model="form['file_bpjs']"
                    accept="image/*"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here..."
                    @input="(file) => previewImage(file, 'file_bpjs_url')"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="AKTA KELAHIRAN"
                label-for="akta-kelahiran"
              >
                <preview-dokumen :url="form['file_akta_url']" />
                <b-input-group>
                  <b-form-file
                    v-model="form['file_akta']"
                    accept="image/*"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here..."
                    @input="(file) => previewImage(file, 'file_akta_url')"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="SIM"
                label-for="sim"
              >
                <preview-dokumen :url="form['file_sim_url']" />
                <b-input-group>
                  <b-form-file
                    v-model="form['file_sim']"
                    :value="form['file_sim']"
                    accept="image/*"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here..."
                    @input="(file) => previewImage(file, 'file_sim_url')"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- End Group 1 -->
            <!-- Start Group 2 -->
            <b-col md="4">
              <b-form-group
                label="NPWP"
                label-for="npwp"
              >
                <preview-dokumen :url="form['file_npwp_url']" />
                <b-input-group>
                  <b-form-file
                    v-model="form['file_npwp']"
                    accept="image/*"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here..."
                    @input="(file) => previewImage(file, 'file_npwp_url')"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            
            <b-col md="4">
              <b-form-group
                label="DOKUMEN PENDUKUNG LAINNYA"
                label-for="LAINNYA"
              >
                <b-input-group>
                  <b-form-file
                    v-model="form['file_lainnya']"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a file or drop it here..."
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- End Group 2 -->
          </b-row>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- </b-card> -->
  </b-modal>
</template>

<script>
import HasToast from '@core/mixins/ui/toast'
import store from '@/store/index'
import {mapGetters, mapState} from 'vuex'
import {mapFields} from 'vuex-map-fields'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import PreviewDokumen from '@/components/pasien/PreviewDokumen.vue'
import axiosIns from '@/libs/axios'

export default {
  name: 'ModalPasienCreate',
  components: {
    PreviewDokumen,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [HasToast],
  inject: ['settingVal'],
  props: {
    type: {
      type: String,
      default: 'create'
    },
    autoNavigate: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalTitle: this.type === 'create'
        ? 'Pendaftaran Pasien Baru'
        : 'Edit Data Pasien',
      modalVisible: this.visible,
      email,
      required,
      errors: [],
      tabIndex: 1,
      selected: null,
      sameAsIdentitas: true,
      sameAsPhone: true,
      sameAsPhoneKeluarga: false,
    }
  },
  computed: {
    editableNorm() {
      return this.settingVal('master:patient:input-norm')
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    nowDate() {
      const data = new Date()
      const year = data.getFullYear()
      const month = this.alterZero((data.getMonth() + 1))
      const day = this.alterZero(data.getDate())
      return `${year}-${month}-${day}`
    },
    ...mapState('wilayah', ['provinsi', 'wilayah', 'kecamatan', 'kelurahan']),
    ...mapState('wilayahIdentitas', {
      provinsi_identitas: 'provinsi',
      wilayah_identitas: 'wilayah',
      kecamatan_identitas: 'kecamatan',
      kelurahan_identitas: 'kelurahan'
    }),
    ...mapGetters('pasien', ['references', 'alamatIdentitas']),
    ...mapFields('pasien', ['form']),
    ...mapState('pasien', ['negara'])
  },
  watch: {
    visible(val) {
      this.modalVisible = val
    },
    alamatIdentitas: {
      handler() {
        this.syncAlamat()
      },
      immediate: true,
      deep: true
    },
    sameAsIdentitas: {
      handler() {
        this.syncAlamat()
      },
      immediate: true
    },
    sameAsPhone: {
      handler() {
        this.syncPhone()
      },
      immediate: true
    },
    sameAsPhoneKeluarga: {
      handler() {
        this.syncPhoneKeluarga()
      },
      immediate: true
    }
  },
  beforeMount() {
    this.$store.dispatch('wilayah/init')
    this.$store.dispatch('wilayahIdentitas/init')
    this.$store.dispatch('pasien/getReferences')
  },
  mounted() {
    this.$root.$on('bv::modal::show', async (_, __) => {
      if (this.type === 'create') {
        this.form.jenis_identitas =
            this.references['Jenis Kartu Identitas'].find((k) => k.value === 1) ||
            null
        this.form.id_keluarga =
            this.references['Jenis Kartu Identitas'].find((k) => k.value === 1) ||
            null
        this.form.kewarganegaraan =
            this.references.kewarganegaraan.find((k) => k.value === 1) ||
            null
        this.form.kewarganegaraan_keluarga =
            this.references.kewarganegaraan.find((k) => k.value === 1) ||
            null
      }
      // if (this.editableNorm && this.type === 'create') this.getLatestNorm()
    })
  },
  methods: {
    closeModal() {
      this.modalVisible = false
      this.$store.dispatch('app/removeOperation', ['createKunjungan'])
      this.$emit('closeModal')
      this.$bvModal.hide('modal-' + this.type)
    },
    alterZero(val) {
      let setup = ''
      if (val.toString().length === 1) {
        setup = `0${val.toString()}`
      } else {
        setup = val
      }

      return setup
    },
    checkFormValidity() {
      const valid = this.$refs.addPasien.validate()
      this.errors = valid
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.checkFormValidity().then(console.log)
      this.$refs.addPasien.validate().then((success,b,c) => {
        if (success) {
          // Trigger submit handler
          // eslint-disable-next-line
          this.formSubmitted()
          requestAnimationFrame(() => {
            this.$refs.addPasien.reset()
          })
        }
      })
    },
    async formSubmitted() {
      try {
        let success
        let message
        let data
        // validation formatted date
        const validate = this.validateDate()
        if (validate === 0) {
          this.toast(
            'Ups, format tanggal salah',
            'danger',
            'AlertTriangleIcon',
            '👋 Harap periksa kembali isianmu. 🙅‍♂️🙅'
          )
        } else {
          if (this.type === 'create') {
            const checkNorm = await this.checkNorm()
            if (!checkNorm) return;
            [success, message, data] = await this.$store.dispatch('pasien/savePasien')
          } else {
            [success, message, data] = await this.$store.dispatch('pasien/updatePasien')
          }
          if (!success) throw new Error(message)
          const {norm = false} = data
          if (norm !== false && this.autoNavigate) {
            this.$router.push({name: 'apps-pasien-detail', params: {norm}})
          }
          this.toast('Sukses Menambahkan Pasien Baru','success', 'CheckCircleIcon')
          this.$refs.modalCreate.hide()
          this.$emit('refreshData')
        }
      } catch (err) {
        console.log(err)
        this.toast(err?.message ?? 'Data Karyawan Gagal Disimpan!', 'danger', 'AlertTriangleIcon', '👋 Harap periksa kembali isianmu. 🙅‍♂️🙅')
      }
    },
    validateDate() {
      const tanggalLahirPasien = this.form.tanggal_lahir_formatted
      const tanggalLahirKeluarga = this.form.tanggal_lahir_keluarga_formatted
      const arr = []
      arr.push(
        tanggalLahirPasien,
        tanggalLahirKeluarga
      )
      const filter1 = arr.filter(item => item != null)
      const finalFilter = filter1.filter(item => item !== '')
      let check = 1
      for (let a = 0; a < finalFilter.length; a++) {
        if (finalFilter[a] != null || finalFilter[a] !== '') {
          check = this.getDateYear(finalFilter[a])
        }
      }

      return check
    },
    getDateYear(dates) {
      const maxDate = 120
      const data = new Date(dates)
      const year = data.getFullYear()
      const month = (data.getMonth() + 1)
      const day = data.getDate()
      const now = new Date()
      if ((now.getFullYear() - year) > maxDate) {
        return 0
      }
      if (now === year) {
        if ((now.getMonth() + 1) === month) {
          if (day > now.getDate()) {
            return 0
          }
        }
      } else {
        return 1
      }
      return 0
    },
    async validate(ref) {
      const request = await this.$refs[ref].validate()
      return request
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null
    },
    syncAlamat() {
      if (this.sameAsIdentitas) {
        this.form.alamat = this.form.alamat_identitas
        this.form.provinsi = this.form.provinsi_identitas
        this.form.wilayah = this.form.wilayah_identitas
        this.form.kecamatan = this.form.kecamatan_identitas
        this.form.kelurahan = this.form.kelurahan_identitas
        this.form.rt = this.form.rt_identitas
        this.form.rw = this.form.rw_identitas
        this.form.kodepos = this.form.kodepos_identitas
      }
    },
    syncPhone() {
      if (this.sameAsPhone) {
        this.form.nomor_kontak_wa = this.form.nomor_kontak_telp
      }
    },
    syncPhoneKeluarga() {
      if (this.sameAsPhoneKeluarga) {
        this.form.nomor_kontak_keluarga_wa =
            this.form.nomor_kontak_keluarga_telp
      }
    },
    previewImage(file, form) {
      if (!file) return
      this.form[form] = URL.createObjectURL(file)
      this.$forceUpdate()
    },
    async nikHandler(nik, form, jk) {
      if (nik.length >= 12) {
        const d = this.getDateFromNik(nik)
        this.form[form] = this.$moment(d.date).format('DD/MM/YYYY')
        this.jkFromNik(d, jk)

        if (nik.length === 16 && this.form.jenis_identitas?.value === 1) {
          const availability = await this.$store.dispatch('pasien/nik', nik)

          if (availability && availability.data?.data?.exists)
            return this.duplicateNik(availability.data.data)
        }
      } else if (nik.length < 12) {
        this.form[form] = null
      }
      return null
    },
    jkFromNik(parsed, _) {
      if (parsed.d > 40) {
        this.form.jenis_kelamin = this.references['Jenis Kelamin'].find(jk => jk.value === 2) || null
      } else {
        this.form.jenis_kelamin = this.references['Jenis Kelamin'].find(jk => jk.value === 1) || null
      }
    },
    getDateFromNik(nik) {
      const currentNik = nik.toString()

      const dateString = currentNik.substring(6, 12)

      let day = dateString.substring(0, 2)
      const month = dateString.substring(2, 4)
      let year = dateString.substring(4, 6)

      if (day > 40) {
        day -= 40
      }

      if (parseInt(`20${year}`, 10) > new Date().getFullYear()) {
        year = `19${year}`
      } else {
        year = `20${year}`
      }

      return {
        date: new Date(`${year}-${month}-${day}`),
        d: dateString.substring(0, 2),
        m: month,
        y: year
      }
    },
    duplicateNik(data) {
      const h = this.$createElement
      const {norm} = data
      const messageVNode = h('div', {class: ['foobar']}, [
        h('p', {class: ['text-center']}, [
          'Pasien tersebut sudah terdaftar dengan: ',
          h('h2', {class: 'mt-1'}, `${data.nama} - ${norm}`),
          'Anda ingin menampilkan data pasien ini?'
        ])
      ])

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: 'Pencarian Pasien',
          buttonSize: 'sm',
          centered: true,
          size: 'sm',
          okTitle: 'Ya',
          cancelTitle: 'Tidak',
          footerClass: 'd-flex mx-auto justify-content-center'
        })
        .then((val) => {
          if (val) {
            this.closeModal()
            this.$router.push({name: 'apps-pasien-detail', params: {norm}})
          }
        })
        .catch(() => {
        })
    },
    modalPasien() {
    },
    async autofillBy(type, val) {
      try {
        const {data} = await this.$axios.get(`/pasien/bpjs/${type}/${val}`);
        if (!data.data) {
          this.toast("danger", {
            title: "Peringatan",
            message: data?.message || "Data pasien tidak dapat diambil"
          });
          return;
        }
        const {
          noKartu,
          nama,
          // hubunganKeluarga,
          sex,
          tglLahir,
          noHP,
          noKTP,
        } = data.data;
        this.form.nik = noKTP;
        this.form.no_bpjs = noKartu;
        this.form.nama = nama;
        this.form.jenis_kelamin = sex === "P" ? 2 : 1;
        this.form.tanggal_lahir_formatted = tglLahir.replace(/-/g, '/');
        this.form.nomor_kontak_telp = noHP;
        this.form.nomor_kontak_wa = noHP;
      } catch (error) {
        if (error.response?.data?.message) {
          this.toast(error.response?.data?.message, 'danger', 'AlertTriangleIcon');
          return;
        }
        console.error(error);
        this.toast("Data pasien tidak dapat diambil", 'danger', 'AlertTriangleIcon');
      }
    },

    getLatestNorm() {
      axiosIns.get('/pasien/free-norm').then(({data}) => {
        this.form = {...this.form, 'norm': data.data}
      })
    },
    async checkNorm(toastOk = false) {
      try {
        const response = await axiosIns.get(`/pasien/check-norm/${this.form.norm}`)
        if (!response.data.data.ok) {
          const confirm = await this.$swal({
            title: 'NORM telah digunakan',
            text: `Ubah NORM menggunakan NORM yang tersedia ? (${response.data.data.freeNorm})`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          })
          if (confirm.isConfirmed) this.form.norm = response.data.data.freeNorm
          return false
        }
        if ((this.form.norm - response.data.data.freeNorm) > 100) {
          const confirm = await this.$swal({
            title: 'Tetap Lanjukan ?',
            text: `NORM terlalu jauh dengan NORM yang terdaftar, NORM selanjutnya akan dimulai dari ${parseInt(this.form.norm, 10) + 1}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          })
          if (!confirm.isConfirmed) return false;
          return true;
        }
        if (toastOk) {
          this.toast('NORM dapat digunakan', 'success', 'CheckCircleIcon');
        }
        return true;
      } catch (error) {
        if (error.response?.data?.message) {
          this.toast(error.response?.data?.message, 'danger', 'AlertTriangleIcon');
          return false;
        }
        console.error(error);
        this.toast("Data norm tidak dapat diambil", 'danger', 'AlertTriangleIcon');
        return false;
      }
    }
  }
}
</script>

