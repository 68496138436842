<template>
  <div>
    <b-form-select v-model="mode" class="pb-1 border-bottom mb-1">
      <option value="0">Semua</option>
      <option value="1">Kosong</option>
      <option value="2">Dipesan</option>
      <option value="3">Terisi</option>
      <option value="4">Tidak Aktif</option>
    </b-form-select>
    <transition-group name="fade" class="beds-container" mode="out-in">
      <button
        :key="bed.id"
        class="text-center bed"
        :class="classByStatus(bed.status)"
        style="border: gray 1px solid; position: relative; text-align: start"
        @click="selectBed(bed)"
        v-for="bed in beds"
      >
        <div style="text-align: start;">
          <strong class="d-block mb-1 border-bottom">{{ ruanganName }}</strong>
          <div>{{ bed.nama_bed }}</div>
          | <span class="text-muted">{{ bedStatus(bed.status) }}</span>
        </div>
      </button>
    </transition-group>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios';
import { ref, computed, watch } from '@vue/composition-api';

export default {
  name: 'PanelBed',
  props: {
    ruanganId: {
      type: String,
      default: null
    },
    ruanganName: {
      type: String,
      default: null
    }
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const beds = ref([])
    const loading = ref(false)
    const mode = ref(1)

    const filteredBeds = computed(() => {
      if (mode.value == 0) return [...beds.value]
      return [...beds.value].filter(bed => bed.status == mode.value)
    });

    function loadBeds() {
      console.log('loadBeds')
      if (!props.ruanganId) {
        beds.value = [];
        return;
      }
      if (loading.value) return
      loading.value = true
      axiosIns.get(`ruangan/bed/${props.ruanganId}`).then(res => {
        beds.value = res.data.map(d => ({ ...d, edit: false, statusAktif: d.status === 1 }));
      }).finally(() => {
        loading.value = false
      })
    }

    loadBeds()
    watch(() => props.ruanganId, loadBeds)

    function classByStatus(status) {
      switch (status) {
        case 1:
          return 'bed-empty'
        case 2:
          return 'bed-booked'
        case 3:
          return 'bed-occupied'
        default:
          return 'bed-inactive'
      }
    }

    function bedStatus(status) {
      switch (status) {
        case 1:
          return 'Kosong'
        case 2:
          return 'Dipesan'
        case 3:
          return 'Terisi'
        default:
          return 'Tidak Aktif'
      }
    }

    function selectBed(bed) {
      console.log('selectBed', bed)
      if (bed.status != 1) return;
      console.log('pl')
      emit('selected', bed)
    }

    return {
      beds: filteredBeds,
      classByStatus,
      bedStatus,
      mode,
      selectBed,
    }
  }
}
</script>

<style lang="scss" scoped>
.beds-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  gap: 1em;

  .bed {
    position: relative;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    margin-bottom: 0;
    padding: 1em;
    border-radius: .5em;
  }
  .bed:hover, .bed:active { transform: translateY(-2.5px) scale(1.01); box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); border-radius: .1em; }
}

.bed-empty {
  background-color: #ffffff18;
  &:hover { background-color: #fefefe26; }
}
.bed-booked {
  background-color: #3321bb18;
  &:hover { background-color: #3321bb26; }
}
.bed-occupied {
  background-color: #12c74e18;
  &:hover { background-color: #12c74e26; }
}
.bed-inactive {
  background-color: #ff000018;
  &:hover { background-color: #ff000026; }
}
</style>
